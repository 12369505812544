import {
  Colors,
  IconCheck,
  IconX,
  Radius,
  space,
  Weight,
} from '@workos-inc/ui-kit';
import { motion } from 'framer-motion';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { SupportedDirectoryType } from 'interfaces/supported-directory-type';
import { useRouter } from 'next/router';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

export type StepsNavProps = {
  onChangeStep: (step: number) => void;
  steps: Step[];
  stepsWithError: number[];
  provider: SupportedConnectionType | SupportedDirectoryType | undefined;
};

export const StepsNav: FC<Readonly<StepsNavProps>> = ({
  onChangeStep,
  steps,
  stepsWithError,
  provider,
}) => {
  const confirmationStep = steps.length;
  const { query } = useRouter();
  const stepsCompleted = Number(query.step ?? 1) - 1;

  const getStepIcon = (index: number): ReactElement | null => {
    const isCompleted = index < stepsCompleted;
    const hasError = stepsWithError.includes(index + 1);

    if (hasError) {
      return (
        <StyledIcon className="icon--red">
          <IconX className="icon" />
        </StyledIcon>
      );
    }

    if (index + 1 === confirmationStep && stepsWithError.length > 0) {
      // don't render a green check for confirmation step if we any errors
      return null;
    }

    if (isCompleted) {
      return (
        <StyledIcon className="icon--green">
          <IconCheck className="icon" />
        </StyledIcon>
      );
    }

    return null;
  };

  return (
    <Nav>
      <StyledTitle>Steps</StyledTitle>

      <StyledList animate="show" initial="hidden" variants={listVariants}>
        {steps.map((step: Step, index: number) => {
          const isActive = index + 1 === Number(query.step);
          const isClickable = index <= stepsCompleted;

          return (
            <StyledStep
              key={`${provider}-${step.name}`}
              isActive={isActive}
              isClickable={isClickable}
              variants={itemVariants}
            >
              <StyledClickContainer
                isClickable={isClickable}
                onClick={() => isClickable && onChangeStep(index + 1)}
              >
                {getStepIcon(index)}
                {step.name}
              </StyledClickContainer>
            </StyledStep>
          );
        })}
      </StyledList>
    </Nav>
  );
};

export type Step = {
  name: string;
};

type StepProps = {
  isActive: boolean;
  isClickable: boolean;
  theme: {
    primary: string;
  };
};

const getStepColor = (props: StepProps): string => {
  if (props.isActive) {
    return props.theme.primary;
  }
  if (props.isClickable) {
    return Colors.TonedMidGray;
  }
  return Colors.GrayLight;
};

const Nav = styled.nav`
  align-items: start;
  display: flex;
  flex-direction: column;
`;

const StyledClickContainer = styled.div`
  cursor: ${(props: { isClickable: boolean }) =>
    props.isClickable ? 'pointer' : 'auto'};
`;

const StyledIcon = styled.i`
  border-radius: ${Radius.Rounded};
  width: 16px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  text-align: center;
  margin-right: ${space.spacing(2)};
  background-color: red;

  &.icon--green {
    color: #27ae35;
    background-color: #dbf3dd;
  }

  &.icon--red {
    color: #d03d3d;
    background-color: #ffd1d1;
  }

  .icon {
    width: 10px;
    height: 10px;
    stroke-width: 5px;
  }
`;

const StyledList = styled(motion.ol)`
  margin-left: ${space.spacing(4)};
`;

const StyledStep = styled(motion.li)`
  vertical-align: middle;
  font-size: 15px;
  font-weight: ${Weight.Regular};
  color: ${getStepColor};
  margin-bottom: ${space.spacing(4)};

  ${(props: { isActive: boolean; isClickable: boolean }) =>
    !props.isActive &&
    props.isClickable &&
    `
    list-style: none;
    margin-left: -${space.spacing(5)};
  `};
`;

const StyledTitle = styled.h3`
  font-size: 19px;
  font-weight: ${Weight.Medium};
  color: ${Colors.TonedDarkGray};
  margin-bottom: ${space.spacing(6)};
`;

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  show: { opacity: 1, x: 0 },
};
